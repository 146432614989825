const hamburger = document.querySelector(".hamburger");
const mobileMenu = document.querySelector(".mobile-menu");
const closeMenu = document.querySelector(".close-mobile-menu");

if (hamburger && mobileMenu) {
  hamburger.addEventListener("click", () => {
    mobileMenu.classList.add("mobile-menu--active");
  });
  closeMenu.addEventListener("click", () => {
    mobileMenu.classList.remove("mobile-menu--active");
  });
}
